export const ColorList = [
  { label: '全部', value: 'ALL' },
  { label: '下单color', value: 'ORDER' },
  { label: '非下单color', value: 'NO_ORDER' }
]

export const ChannelList = [
  { label: '全部', value: 'ALL' },
  { label: '下单备货渠道', value: 'ORDER' },
  { label: '非下单备货渠道', value: 'NO_ORDER' }
]

export const Columns = [
  { prop: 'styleName', label: 'Style' },
  { prop: 'colorNameDisplay', label: 'Color' },
  { prop: 'sizeNameDisplay', label: 'Size' },
  { prop: 'skuNameDisplay', label: 'Sku' },
  { prop: 'stockChannelNameDisplay', label: '备货渠道' },
  { prop: 'overboughtNumberDisplay', label: '超买数量' }

]
