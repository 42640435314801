<template>
  <div class="table_main">
    <vxe-table
      ref="xTable"
      max-height="500"
      border
      :loading="loading"
      show-overflow
      show-footer
      :cell-style="cellStyle"
      :footer-method="footerMethod"
      :data="searchedTableData"
      :scroll-y="{ enabled: true, }"
      :scroll-x="{ enabled: true, }"
      align="center"
    >
      <vxe-column type="seq" width="60" />

      <vxe-column field="colorName" title="Color" width="200">
        <template #default="{ row }">
          <div style="margin-bottom:-6px">{{ row.colorName }}</div>
          <div>{{ `MOQ/待凑单量:${row.colorMoq}/${row.moqTobeAdded}` }}</div>
        </template>
      </vxe-column>
      <vxe-column field="sizeName" title="Size" width="90" />
      <vxe-column field="skuName" title="Sku" width="130" />
      <vxe-column field="cargoType" title="装箱数" width="90" />
      <vxe-column field="stockChannelName" title="备货渠道" width="100" />
      <vxe-column field="planPurchaseNumber" title="计划采购数量" width="120" />
      <vxe-column width="120" field="overboughtNumber" title="超买数量">
        <template #default="{ row }">
          <el-input-number
            v-model="row.overboughtNumber"
            :min="0"
            :max="999999"
            :precision="0"
            :controls="false"
            style="width: 100%"
            @change="val=>handleOverboughtNumber(val,row)"
            @input="$refs.xTable.updateFooter()"
          />
        </template>

      </vxe-column>
      <!-- <vxe-colgroup title="净下单需求"> -->
      <vxe-column
        v-for="(e,idx) in monthList"
        :key="idx"
        :field="e"
        width="120"
        :title="e"
      >
        <template #default="{ row }">
          {{ row[e]||0 }}
        </template>
      </vxe-column>
      <vxe-column width="120" field="totalNetDemandQty" title="合计" />
      <!-- </vxe-colgroup> -->
    </vxe-table>
  </div>
</template>
<script>
import XEUtils from 'xe-utils'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => [{}]
    },
    monthList: {
      type: Array,
      default: () => []
    },
    tableFilterData: {
      type: Array,
      default: () => [{}]
    },
    queryForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      detailList: [],
      tableLoading: false,
      list: [],
      obj: {}
    }
  },
  computed: {
    tableData: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    searchedTableData() {
      if (this.tableFilterData.length === 0 && this.isObjectValues(this.queryForm)) {
        return []
      } else {
        const list = this.tableData.filter(v => this.tableFilterData.length ? this.tableFilterData.some(vv => vv.skuName === v.skuName && vv.stockChannelId === v.stockChannelId) : true)
        const { isOverBought } = this.queryForm
        if (isOverBought === true) {
          return list.filter(v => v.overboughtNumber)
        } else if (isOverBought === false) {
          return list.filter(v => !v.overboughtNumber)
        } else {
          return list
        }
      }
    }
  },
  watch: {

  },

  mounted() {
  },

  methods: {
    cellStyle({ row, column }) {
      if (row.markColorList?.includes(column.property)) {
        return {
          backgroundColor: '#95F204',
          color: '#000000'
        }
      }
    },
    isObjectValues(obj) {
      return Object.values(obj).some(v => (Array.isArray(v) && v.length) || (!Array.isArray(v) && v))
    },
    handleOverboughtNumber(val, row) {
      const { stockChannelId, skuName, colorName, moqTobeAdded } = row
      const obj = this.tableData.find(v => v.stockChannelId === stockChannelId && v.skuName === skuName)
      if (obj) {
        obj.overboughtNumber = val
      }

      if (!val) {
        this.$set(row, 'markColorList', [])
      } else {
        this.handleMarkColorRow(row)
      }
      // 待凑单量=单色MOQ-该色（超买数量+计划采购数量），计算结果小于0，取0
      if (moqTobeAdded === '/') return
      const sum = this.tableData.reduce((acc, { colorName, planPurchaseNumber = 0, overboughtNumber = 0 }) => {
        if (row.colorName === colorName) {
          acc += (planPurchaseNumber + overboughtNumber)
        }
        return acc
      }, 0)
      this.tableData.map(v => {
        if (v.colorName === colorName) {
          v.moqTobeAdded = Math.max(((v.colorMoq || 0) - sum), 0)
        }
      })
    },
    handleMarkColorRow(row) {
      row.markColorList = []
      const { netDemandList = [], overboughtNumber } = row
      // 处理标色
      let sum = 0
      for (var i = 0; i < netDemandList?.length; i++) {
        const { yearMonthStr, netDemandQty } = netDemandList[i]
        sum += netDemandQty
        netDemandQty && row.markColorList.push(yearMonthStr)
        if (sum >= overboughtNumber) {
          break
        }
      }
    },

    footerMethod({ columns, data }) {
      const sum = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['planPurchaseNumber', 'totalNetDemandQty', 'overboughtNumber'].includes(column.property)) {
            return XEUtils.sum(data, column.property)
          }
          if (this.monthList.includes(column.property)) {
            return XEUtils.sum(data, column.property)
          }
          return ''
        })
      ]
      return sum
    }

  }
}
</script>
<style lang="scss" scope>
 .table_main {
    >div.vxe-table {
      margin-bottom: 0;
    }
    .vxe-table:not(:first-child) {
      .vxe-table--header-wrapper {
        display: none;
      }
    }
  }

</style>

