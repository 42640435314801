<template>
  <div>
    <QueryForm
      v-model="queryForm"
      :query-btn-loading="tableLoading"
      @query="handleQueryForFilter"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="Color">
        <Select
          v-model="queryForm.selectColorNameList"
          :select-options="colorOptions"
          :configuration="{ key: 'id', label: 'colorName', value: 'colorName' }"
          multiple
        />
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="queryForm.selectColorType">
          <el-radio
            v-for="(item, index) in ColorList"
            :key="index"
            :label="item.value"
          >{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Size">
        <Select
          v-model="queryForm.selectSizeNameList"
          :select-options="sizeOptions"
          multiple
          :configuration="{ key: 'id', label: 'size', value: 'size' }"
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input
          v-model="queryForm.selectSkuNameList"
          placeholder="多个以换行符分隔"
          type="textarea"
          style="width: 100%"
          autosize
          resize="none"
        />
      </el-form-item>
      <el-form-item label="备货渠道">
        <Select
          v-model="queryForm.selectStockChannelIdList"
          api-key="StockChannelList"
          multiple
          filterable
          collapse-tags
        />
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="queryForm.selectStockChannelType">
          <el-radio
            v-for="(item, index) in ChannelList"
            :key="index"
            :label="item.value"
          >{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否超买">
        <Select
          v-model="queryForm.isOverBought"
          :select-options="[{label:'是',val:true},{label:'否',val:false}]"
        />
      </el-form-item>
    </QueryForm>
    <el-row type="flex" style="font-size: 14px">
      <el-col>
        <el-button
          type="text"
          :loading="exportLoading"
          class="mr-2"
          @click="handleExport"
        >导出</el-button>
        <ImportFile
          ref="uploadForm"
          v-model="uploadDetailVisible"
          :can-check="false"
          button-type="text"
          :upload-file="handleImport"
          :auto-upload="true"
          :import-details-columns="Columns"
          :details-data="importDatas"
          :show-confirm="true"
          style="display: inline-block !important"
          import-name="导入"
          @submitDetailDatas="submitImportDatas"
          @getFile="({ file: val }) => (file = val)"
        />
      </el-col>
      <el-col>
        <span>Style:{{ obj.styleName }}</span>
        <span class="ml-3 mr-3">供应商:{{ obj.vendorName }}</span>
        <span>单款最小起订量:{{ obj.styleMoq }}</span>
        <span class="ml-3">单款待凑单量:{{ updatedWaitedAmount }}</span>
      </el-col>
    </el-row>

    <Sku
      ref="skuRef"
      v-model="tableData"
      :table-filter-data="tableFilterData"
      :loading="tableLoading"
      :month-list="monthList"
      :query-form="queryFormForSearch"
    />

    <div style="text-align: center; margin-top: 30px">
      <el-button @click="handleBack">返回</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit"
      >提交</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Sku from './components/Sku'
import { handleDownload } from '@/utils'
import { querySizeList, queryColorList } from '@/api/scm-api'
import {
  overboughtSku,
  overboughtSubmitValidate,
  overboughtExport,
  overboughtImport
} from '@/api/purchasebussiness/purchaseplan.js'
import { preText } from '@/utils/index.js'
import XEUtils from 'xe-utils'
import NP from 'number-precision'
import ImportFile from '@/components/ImportFile'
import { ColorList, ChannelList, Columns } from './helper.js'

export default {
  components: { QueryForm, Select, Sku, ImportFile },

  data() {
    return {
      uploadDetailVisible: false,
      importDatas: [],
      Columns,
      uploadVisible: false,
      exportLoading: false,
      monthList: [],
      detailList: [],
      submitLoading: false,
      ColorList,
      ChannelList,
      tableLoading: false,
      queryForm: {
        isOverBought: '',
        selectSkuNameList: '',
        selectColorNameList: []
      },
      queryFormForSearch: {},
      tableData: [{}],
      tableColorData: [{}],
      colorOptions: [],
      sizeOptions: [],
      tableFilterData: [],
      list: [],
      obj: {
        styleName: '',
        vendorName: ''
      },
      file: ''
    }
  },
  computed: {
    updatedWaitedAmount() {
      const sum = this.tableData.reduce(
        (acc, { planPurchaseNumber = 0, overboughtNumber = 0 }) => {
          acc += NP.plus(planPurchaseNumber, overboughtNumber)
          return acc
        },
        0
      )
      return Math.max(NP.minus(this.obj.styleMoq || 0, sum), 0)
    },
    queryParams() {
      const { selectSkuNameList } = this.queryForm
      const confirmColorList = this.list?.map((v) => v.colorName)
      const { vendorId, vendorName, styleName, purchasePlanCodeList } =
        this.obj
      return Object.assign(
        {},
        { vendorId, vendorName, styleName },
        this.queryForm,
        {
          confirmColorList,
          selectSkuNameList: preText(selectSkuNameList, true),
          purchasePlanCodeList: purchasePlanCodeList?.split(',')
        }
      )
    },
    submitParams() {
      const { vendorId, vendorName, styleName } = this.obj
      const detailList = this.detailList.map((v) => {
        return { ...v, overboughtNumber: v.overboughtNumber || 0 }
      })
      return Object.assign({}, { detailList, vendorId, vendorName, styleName })
    },
    exportParams() {
      const detailList = this.$refs.skuRef.searchedTableData.map((v) => {
        return { ...v, overboughtNumber: v.overboughtNumber || 0 }
      })
      return Object.assign({}, { detailList, monthList: this.monthList })
    },
    ...mapGetters(['addOverboughtDataObject', 'currentSkuChannelOverboughtList', 'OverboughtSkuTableData'])
  },
  created() {
    const { multipleSelection, params } = this.addOverboughtDataObject
    this.list = multipleSelection && JSON.parse(multipleSelection)
    this.obj = (params && JSON.parse(params)) || {
      vendorName: '',
      styleName: '',
      waitedAmount: '',
      styleMoq: ''
    }
  },
  mounted() {
    this._queryColorOptions()
    this._querySizeOptions()
    this.handleQuery()
  },

  methods: {
    submitImportDatas() {
      this.updateTableData(this.importDatas)
      this.uploadDetailVisible = false
    },

    async handleImport() {
      try {
        const formData = new FormData()
        formData.append('file', this.file)
        const params = Object.assign(
          {},
          { styleName: this.obj.styleName, detailList: this.tableData }
        )
        formData.append('json', JSON.stringify(params))
        const { datas } = await overboughtImport(formData)
        this.importDatas = datas
        this.uploadDetailVisible = true
        this.file = ''
      } finally {
        this.$refs.uploadForm.clearFiles()
      }
    },
    async handleExport() {
      try {
        this.exportLoading = true
        const data = await overboughtExport(this.exportParams)
        handleDownload(data, '超买数量.xlsx')
      } finally {
        this.exportLoading = false
      }
    },

    updateTableData(val) {
      this.tableData = this.tableData?.map((v) => {
        const { skuName, stockChannelName } = v
        val?.map((vv) => {
          const { skuName: sku, stockChannelName: stockName } = vv
          if (skuName === sku && stockName === stockChannelName) {
            v.overboughtNumber = vv.overboughtNumber
          }
        })
        return { ...v }
      })
      // 更新标色
      this.handleMarkColor()
    },
    handleBack() {
      const currentQuery = Object.assign({}, this.$route.query, {
        fromPath: 'addoverboughtback',
        canUpRound: true
      })
      this.$router.push({
        name: 'GenerateOrder',
        append: true,
        query: currentQuery
      })
    },

    async handleSubmit() {
      try {
        this.detailList = this.tableData

        if (this.detailList.every((v) => !Number(v.overboughtNumber))) {
          return this.$message.warning('至少一个sku+备货渠道需要有超买数量')
        }

        this.submitLoading = true
        // 成功后带标识和color维度数据返回生成采购订单step1页面
        const { datas } = await overboughtSubmitValidate(this.submitParams)
        const msgFlag = Array.isArray(datas) && datas.length
        if (msgFlag) {
          const msg = datas.join(';')
          this.$confirm(`${msg} 是否继续操作？`, '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleConfirm()
          })
        } else {
          this.handleConfirm()
        }
      } finally {
        this.submitLoading = false
      }
    },
    handleConfirm() {
      this.$store.commit('pages/SET_MOQ_COMFIRM_TABLEDATA', this.tableData)

      const currentQuery = Object.assign({}, this.$route.query, {
        fromPath: 'addoverboughtcomfirm'
      })

      this.$router.push({
        name: 'GenerateOrder',
        append: true,
        query: currentQuery
      })
    },

    async _queryColorOptions() {
      const { datas } = await queryColorList({
        styleId: this.$route.query.styleId
      })
      this.colorOptions = datas
    },
    async _querySizeOptions() {
      const { datas } = await querySizeList({
        styleId: this.$route.query.styleId
      })
      this.sizeOptions = datas
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm

      this.handleQueryForFilter()
    },
    async handleQueryForFilter() {
      try {
        this.tableLoading = true
        this.queryFormForSearch = { ...this.queryForm }
        const {
          datas: { detailList = [] }
        } = await overboughtSku(this.queryParams)
        this.tableFilterData = detailList
      } finally {
        this.tableLoading = false
      }
    },
    handleMarkColor() {
      this.tableData.map(row => {
        row.markColorList = []
        const { netDemandList = [], overboughtNumber } = row
        const compareNum = overboughtNumber
        let sum = 0
        for (var i = 0; i < netDemandList?.length; i++) {
          const { yearMonthStr, netDemandQty } = netDemandList[i]
          sum = NP.plus(sum, netDemandQty)
          compareNum && netDemandQty && row.markColorList.push(yearMonthStr)
          if (sum >= compareNum) {
            break
          }
        }
      })
    },
    async handleQuery() {
      try {
        this.tableLoading = true
        const {
          datas: { detailList = [], monthList = [] }
        } = await overboughtSku(this.queryParams)
        this.monthList = monthList
        this.tableData = detailList.map((v) => {
          let currentDemand = {}
          const { stockChannelId, colorName, netDemandList = [], skuName } = v
          const sameColor = this.list.find((v) => v.colorName === colorName)
          if (sameColor) {
            const { colorMoq, moqTobeAdded } = sameColor
            this.$set(v, 'colorMoq', colorMoq || '')
            this.$set(v, 'moqTobeAdded', moqTobeAdded)
          }

          const sameSku = this.currentSkuChannelOverboughtList.find(v => v.stockChannelId === stockChannelId && v.skuName === skuName)
          if (sameSku) {
            v.overboughtNumber = sameSku.overboughtNumber
          }

          currentDemand = netDemandList?.reduce(
            (acc, { netDemandQty, yearMonthStr }) => {
              acc[yearMonthStr] = netDemandQty
              return acc
            },
            {}
          )

          return { ...v, ...currentDemand }
        })
        this.tableData = Object.freeze(this.tableData)
        this.handleMoqToBeAdd()
        // 更新标色
        this.handleMarkColor()
      } finally {
        this.tableLoading = false
      }
    },
    handleMoqToBeAdd() {
      // 待凑单量=单色MOQ-该色（超买数量+计划采购数量），计算结果小于0，取0
      this.tableData.map(v => {
        const { moqTobeAdded, colorName } = v
        if (moqTobeAdded === '/') return
        const sum = this.tableData.reduce((acc, { colorName, planPurchaseNumber = 0, overboughtNumber = 0 }) => {
          if (v.colorName === colorName) {
            acc += (planPurchaseNumber + overboughtNumber)
          }
          return acc
        }, 0)
        if (v.colorName === colorName) {
          v.moqTobeAdded = Math.max(((v.colorMoq || 0) - sum), 0)
        }
      })
    },
    footerMethod({ columns, data }, index) {
      const sum = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (
            [
              'planPurchaseNumber',
              'totalNetDemandQty',
              'overboughtNumber'
            ].includes(column.property)
          ) {
            return XEUtils.sum(data, column.property)
          }
          if (this.monthList.includes(column.property)) {
            return XEUtils.sum(data, column.property)
          }
          return ''
        })
      ]
      return sum
    }
  }
}
</script>
